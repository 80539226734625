import type {
  AddressInterface,
  GraphNodes,
  ShopifyCustomerInterface,
  GraphPageInfo,
  OrderInterface,
  LocalizationInterface,
  PaginationRequest,
  ProductInterface
} from 'har-shared/types/shopify'
import type { FormError } from '#ui/types'

type GraphError = {
  code?: string | null
  field?: string[] | null
  message?: string | null
}

const QUERY_ENDPOINT = '/api/public/shopify/storefront/query'

export const useStorefrontApi = () => {
  const commonStore = useCommonStore()

  const processErrors = (errors: GraphError[]) => {
    const formErrors: FormError[] = []
    let message = null
    if (errors) {
      errors.forEach((error: any) => {
        if (error.field) {
          formErrors.push({
            path: Array.isArray(error.field) ? error.field.pop() : error.field.toString(),
            message: error.message ?? 'Invalid input'
          })
        }
        if (error.message) {
          message = error.message
        }
      })
    }
    return {
      ...(message ? { message } : {}),
      ...(formErrors ? { formErrors } : {})
    }
  }

  const getLocalization = (): Promise<LocalizationInterface> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `{
          localization {
            availableCountries {
              isoCode
              name
            }
            country {
              isoCode
              name
            }
            availableLanguages {
              isoCode
              name
            }
            language {
              isoCode
              name
            }
          }
        }`
      })
        .then((res) => {
          resolve(res.localization)
        })
        .catch((e) => reject(e))
    })
  }

  const customerAccessTokenCreateWithMultipass = (multipassToken: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `mutation customerAccessTokenCreateWithMultipass {
                customerAccessTokenCreateWithMultipass(multipassToken: "${multipassToken}") {
                    customerAccessToken {
                        accessToken
                    }
                    customerUserErrors {
                        message
                    }
                }
              }`
      })
        .then((res) => {
          const accessToken =
            res?.customerAccessTokenCreateWithMultipass?.customerAccessToken?.accessToken
          if (accessToken) {
            resolve(accessToken)
          } else {
            reject(res)
          }
        })
        .catch((e) => reject(e))
    })
  }

  const customerAccessTokenRenew = (accessToken: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `mutation customerAccessTokenRenew {
              customerAccessTokenRenew(customerAccessToken: "${accessToken}") {
                customerAccessToken {
                  accessToken
                }
                userErrors {
                  field
                  message
                }
              }
            }`
      })
        .then((res) => {
          const accessToken = res?.customerAccessTokenRenew?.customerAccessToken?.accessToken
          if (accessToken) {
            resolve(accessToken)
          } else {
            reject(res)
          }
        })
        .catch((e) => reject(e))
    })
  }

  const getCustomer = (): Promise<ShopifyCustomerInterface> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `{
              customer(customerAccessToken: "${commonStore.customerAccessToken}") {
                  id
                  email
                  firstName
                  lastName
                  displayName
                  acceptsMarketing
                  defaultAddress{
                    id
                    address1
                    address2
                    city
                    company
                    country
                    province
                    zip
                  }
                  tags
                  rechargeCustomerId: metafield(key: "customer_string", namespace: "subscriptions") {
                    value
                  }
              }
            }`
      })
        .then((res) => {
          resolve(res.customer)
        })
        .catch((e) => reject(e))
    })
  }

  const updateCustomer = (
    dto: Partial<ShopifyCustomerInterface>
  ): Promise<{ customerAccessToken: string; customer: ShopifyCustomerInterface }> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `mutation customerUpdate {
                customerUpdate(customer: {
                  acceptsMarketing: ${dto.acceptsMarketing ? 'true' : 'false'},
                  firstName: "${dto.firstName}",
                  lastName: "${dto.lastName}"
                }, customerAccessToken: "${commonStore.customerAccessToken}") {
                  customer{
                    id
                    email
                    firstName
                    lastName
                    displayName
                    acceptsMarketing
                    defaultAddress{
                      id
                      address1
                      address2
                      city
                      company
                      country
                      province
                      zip
                    }
                    tags
                  }
                  customerAccessToken {
                      accessToken
                  }
                  customerUserErrors {
                      code
                      field
                      message
                  }
                }
              }`
      })
        .then((res) => {
          const customer = res?.customerUpdate?.customer
          const accessToken = res?.customerUpdate?.customerAccessToken?.accessToken
          if (!res.customerUserErrors) {
            resolve({
              customerAccessToken: accessToken,
              customer
            })
          } else {
            reject(res.customerUserErrors ?? res)
          }
        })
        .catch((e) => reject(e))
    })
  }

  const getCustomerAddresses = (): Promise<{
    addresses: GraphNodes<AddressInterface>
    defaultAddress: Partial<AddressInterface>
  }> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `{
              customer(customerAccessToken: "${commonStore.customerAccessToken}") {
                  defaultAddress{
                    id
                  }
                  addresses(first:25){
                    nodes{
                      id
                      firstName
                      lastName
                      phone
                      address1
                      address2
                      city
                      company
                      country
                      province
                      zip
                      formatted
                      formattedArea
                    }
                  }
              }
            }`
      })
        .then((res) => {
          resolve(res.customer)
        })
        .catch((e) => reject(e))
    })
  }

  const createAddress = (
    data: AddressInterface
  ): Promise<{ customerAddress: AddressInterface }> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `mutation customerAddressCreate {
          customerAddressCreate(address: {
            firstName: "${data.firstName}",
            lastName: "${data.lastName}",
            phone: "${data.phone}",
            address1: "${data.address1}",
            address2: "${data.address2}",
            city: "${data.city}",
            country: "${data.country}",
            province: "${data.province}",
            zip: "${data.zip}",
          }, customerAccessToken: "${commonStore.customerAccessToken}") {
            customerAddress {
              id
              firstName
              lastName
              phone
              address1
              address2
              city
              company
              country
              province
              zip
              formatted
              formattedArea
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`
      })
        .then((res) => {
          const address = res?.customerAddressCreate?.customerAddress
          if (!res.customerUserErrors) {
            resolve({
              customerAddress: address
            })
          } else {
            reject(processErrors(res.customerUserErrors))
          }
        })
        .catch((e) => reject(e))
    })
  }

  const updateAddress = (
    addressId: string,
    data: AddressInterface
  ): Promise<{ customerAddress: AddressInterface }> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `mutation customerAddressUpdate {
          customerAddressUpdate(
            address: {
              firstName: "${data.firstName}",
              lastName: "${data.lastName}",
              phone: "${data.phone}",
              address1: "${data.address1}",
              address2: "${data.address2}",
              city: "${data.city}",
              country: "${data.country}",
              province: "${data.province}",
              zip: "${data.zip}",
            }, 
            customerAccessToken: "${commonStore.customerAccessToken}",
            id: "${addressId}"
          ) {
            customerAddress {
              id
              firstName
              lastName
              phone
              address1
              address2
              city
              company
              country
              province
              zip
              formatted
              formattedArea
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`
      })
        .then((res) => {
          const address = res?.customerAddressUpdate?.customerAddress
          if (!res.customerUserErrors) {
            resolve({
              customerAddress: address
            })
          } else {
            reject(processErrors(res.customerUserErrors))
          }
        })
        .catch((e) => reject(e))
    })
  }

  const deleteAddress = (addressId: string): Promise<{ deletedCustomerAddressId: string }> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `mutation customerAddressDelete {
          customerAddressDelete(customerAccessToken: "${commonStore.customerAccessToken}", id: "${addressId}") {
            deletedCustomerAddressId
            customerUserErrors {
              code
              field
              message
            }
          }
        }`
      })
        .then((res) => {
          if (!res.customerUserErrors) {
            resolve(res)
          } else {
            reject(processErrors(res.customerUserErrors))
          }
        })
        .catch((e) => reject(e))
    })
  }

  const updateDefaultAddress = (addressId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `mutation customerDefaultAddressUpdate {
          customerDefaultAddressUpdate(addressId: "${addressId}", customerAccessToken: "${commonStore.customerAccessToken}") {
            customerUserErrors {
              code
              field
              message
            }
          }
        }`
      })
        .then((res) => {
          if (!res.customerUserErrors) {
            resolve(res)
          } else {
            reject(processErrors(res.customerUserErrors))
          }
        })
        .catch((e) => reject(e))
    })
  }

  const getCustomerOrders = (
    pagination: PaginationRequest
  ): Promise<{
    orders: GraphNodes<OrderInterface>
    totalCount: number
    pageInfo: GraphPageInfo
  }> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `{
          customer(customerAccessToken:"${commonStore.customerAccessToken}"){
            orders(first: ${pagination.perPage}, sortKey: PROCESSED_AT, reverse: true${
              pagination.after ? ', after: "' + pagination.after + '"' : ''
            }${pagination.before ? ', before: "' + pagination.before + '"' : ''}){
              nodes{
                id
                orderNumber
                name
                email
                processedAt
                canceledAt
                cancelReason
                fulfillmentStatus
                financialStatus
                shippingAddress{
                  address1
                  address2
                  name
                  formatted
                  formattedArea
                }
                subtotalPrice{
                  amount
                  currencyCode
                }
                totalPrice{
                  amount
                  currencyCode
                }
              }
              totalCount
              pageInfo{
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
            }
          }
        }`
      })
        .then((res) => {
          resolve(res.customer)
        })
        .catch((e) => reject(e))
    })
  }

  const getCustomerOrder = (orderId: string): Promise<OrderInterface> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `{
          customer(customerAccessToken:"${commonStore.customerAccessToken}"){
            orders(first: 1, query: "id:${orderId}"){
              nodes {
                id
                orderNumber
                name
                email
                processedAt
                shippingAddress {
                  id
                  name
                  company
                  phone
                  address1
                  address2
                  city
                  province
                  country
                  zip
                  formatted
                  formattedArea
                }
                billingAddress {
                  id
                  name
                  company
                  phone
                  address1
                  address2
                  city
                  province
                  country
                  formatted
                  formattedArea
                  zip
                }
                subtotalPrice {
                  amount
                  currencyCode
                }
                totalPrice {
                  amount
                  currencyCode
                }
                currentSubtotalPrice {
                  currencyCode
                  amount
                }
                currentTotalPrice {
                  amount
                  currencyCode
                }
                currentTotalTax {
                  currencyCode
                  amount
                }
                canceledAt
                cancelReason
                fulfillmentStatus
                financialStatus
                shippingDiscountAllocations {
                  allocatedAmount {
                    amount
                    currencyCode
                  }
                }
                successfulFulfillments {
                  trackingInfo {
                    number
                    url
                  }
                  trackingCompany
                }
                lineItems(first: 25) {
                  nodes {
                    currentQuantity
                    title
                    quantity
                    discountedTotalPrice {
                        currencyCode
                        amount
                    }
                    originalTotalPrice {
                        currencyCode
                        amount
                    }
                    discountAllocations {
                      allocatedAmount {
                        currencyCode
                        amount
                      }
                      discountApplication {
                        targetType
                        targetSelection
                        allocationMethod
                        value {
                          ... on PricingPercentageValue {
                            __typename
                            percentage
                          }
                          ... on MoneyV2 {
                            __typename
                            amount
                            currencyCode
                          }
                        }
                        ... on AutomaticDiscountApplication {
                          __typename
                          title
                        }
                        ... on DiscountCodeApplication {
                          __typename
                          code
                          applicable
                        }
                        ... on ScriptDiscountApplication {
                          __typename
                          title
                        }
                        ... on ManualDiscountApplication {
                          __typename
                          description
                          title
                        }
                      }
                    }
                    variant {
                      id
                      sku
                      title
                      taxable
                      image {
                        altText
                        url
                      }
                      compareAtPrice {
                        amount
                        currencyCode
                      }
                      price {
                        amount
                        currencyCode
                      }
                      unitPrice {
                        currencyCode
                        amount
                      }
                      selectedOptions {
                        name
                        value
                      }
                      sellingPlanAllocations(first: 10) {
                        nodes {
                          sellingPlan {
                            name
                            recurringDeliveries
                            description
                            id
                            options {
                              name
                              value
                            }
                          }
                          priceAdjustments {
                            compareAtPrice {
                              currencyCode
                              amount
                            }
                            price {
                              amount
                              currencyCode
                            }
                          }
                        }
                      }
                    }
                  }
                }
                successfulFulfillments {
                  trackingInfo {
                    number
                    url
                  }
                  trackingCompany
                }
                totalRefunded {
                  amount
                  currencyCode
                }
                totalShippingPrice {
                  amount
                  currencyCode
                }
                totalTax {
                  amount
                  currencyCode
                }
              }
            }
          }
        }`
      })
        .then((res) => {
          const order = res?.customer?.orders?.nodes?.[0]
          if (order) {
            resolve(order)
          } else {
            reject(res)
          }
        })
        .catch((e) => reject(e))
    })
  }

  const getProductByHandle = (
    productHandle: string,
    withVariants: boolean = false
  ): Promise<ProductInterface> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `{
              product(handle: "${productHandle}") {
                id
                title
                vendor
                description
                descriptionHtml
                handle
                onlineStoreUrl
                productType
                availableForSale
                featuredImage {
                  altText
                  url
                  id
                }
                options {
                  id
                  name
                  values
                }
                ${
                  withVariants
                    ? `
                  variants(first: 50) {
                    nodes {
                      title
                      id
                      image {
                        url
                      }
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                `
                    : ''
                }
              }
            }`
      })
        .then((res) => {
          resolve(res.product)
        })
        .catch((e) => reject(e))
    })
  }

  const getProductById = (
    productId: string | number,
    withVariants: boolean = false
  ): Promise<ProductInterface> => {
    return new Promise((resolve, reject) => {
      $fetch<any>(`${QUERY_ENDPOINT}?country=${commonStore.country_code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: `{
              product(id: "gid://shopify/Product/${productId}") {
                id
                title
                vendor
                description
                descriptionHtml
                handle
                onlineStoreUrl
                productType
                availableForSale
                featuredImage {
                  altText
                  url
                  id
                }
                options {
                  id
                  name
                  values
                }
                ${
                  withVariants
                    ? `
                  variants(first: 50) {
                    nodes {
                      title
                      id
                      image {
                        url
                      }
                      compareAtPrice {
                        amount
                        currencyCode
                      }
                      price {
                        amount
                        currencyCode
                      }
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                `
                    : ''
                }
              }
            }`
      })
        .then((res) => {
          resolve(res.product)
        })
        .catch((e) => reject(e))
    })
  }

  return {
    getLocalization,
    customerAccessTokenCreateWithMultipass,
    customerAccessTokenRenew,
    getCustomer,
    updateCustomer,
    getCustomerAddresses,
    createAddress,
    updateAddress,
    deleteAddress,
    updateDefaultAddress,
    getCustomerOrders,
    getCustomerOrder,
    getProductByHandle,
    getProductById
  }
}
